import {
    CREATE_NEW_JOB,
    SET_JOBS_DATA,
    DETAILS_FETCHING_COMPLETE,
    JOBS_FETCHING_COMPLETE,
    SET_JOBS_PAGE,
    CONFIRM_CREATE_JOB,
    PALLETS_OPERATORS_FETCHING_COMPLETE,
    FETCH_ASSET_FILE_DETAILS,
    PALLET_MAIL_SENT_COMPLETE,
    SET_PRINT_PAGE_DATA,
    DELETE_SNAPSHOT_OBJ_SUCCESS,
    SET_PALLET_ARRIVAL_DATE,
    JOB_UPDATE_SUCCESS,
    SET_STATE_VALUES,
    DELETE_JOB,
    DELETE_JOB_DISMISS,
    DELETE_JOB_PREVIEW,
    GROUPED_CONTAINERS_FETCHING_COMPLETE,
    CREATE_NEW_TRIAGE_PROD_JOB,
    FETCH_IMAGE,
    SET_IMAGE_DATA,
    ADD_NEW_POPUP_PREVIEW
} from "./types";

const initialState = {
    jobs_list: [],//holds jobs list array
    jobslist_fetching: false,//indicaction for fetch in progress
    jobslist_refetch: false,//indication to refetch current jobs list array
    job_details_refetch: false,//indication to refetch current jobs details
    jobslist_refetch_force: false,//indication to force refetch jobs list array from beginning 
    search_job: "",//search keyword on jobs listing
    filter_customer: null, // stores id of filter by customer
    filter_status_triage_job: null, // stores flag of status for the job
    page: 1,//page number on jobs listing
    perpage: 10,//perpage limit on jobs listing
    total_jobs: 0,//total count
    data_fetching: false,
    create_jobs_popup: false, // toggle create jobs popup
    job_snapshot_popup: false, // toggle snapshot view popup
    view_job_detail_component: false, // toggle component job detail view
    view_prod_job_detail_component: false, // toggle component prod job detail view
    job_details: null,
    job_id: null,
    job_type: null,
    job_for: 1,
    prod_job_customer_id: null,
    customer: null,
    energy_type_id: null,
    arrival_pallet_quantity: null,
    snapshot_data: {},
    customers_list: [],
    job_types_list: [],
    energy_types_list: [],
    asset_owners_list: [],
    asset_types_list: [],
    asset_manus_list: [],
    // prod_job_asset_owner_id: "",
    prod_job_asset_type_id: "",
    prod_job_energy_type_id: "",
    prod_job_manu_ids: [],
    prod_job_manu_ids_change_flag: null,
    prod_containers_selected_arr: [],
    prod_containers_list: [],
    disable_prod_container_dropdown: true,
    ePallet_qty_flag: false,
    ePallet_qty: "",
    eArrival_date: "",
    eArrival_time: "10:00 AM",
    eArrival_date_flag: false,
    eOperator_flag: false,
    eOperator: "",
    operator_list: [],
    pallet_list: [],
    view_asset_file_detail_component: false,
    asset_file_detail_list: [],
    asset_file_page: 1,
    asset_file_perpage: 10,
    asset_file_detail_list_count: null,
    asset_file_details_refetch: false,
    pallet_email_send: false,
    print_list_arr: [],
    update_btn_disable: false,
    btn_grp_disable: false,
    job_reference_no:"",
    originator: "",
    collect_from: "",
    delivery_flag: "",
    triage_meter_code: null,
    triage_job_file: null,
    eOriginator_flag: false,
    eOriginator: "",
    eCollect_from: "",
    eCollect_from_flag: "",
    triage_file_exist_flag: true,
    image_id:null,
    pallet_asset_id:"",
    image_popup: false,
    image_data:{},
    delete_job: null,//holds initial data of job opted to be deleted,
    inspection_list: [],
    inspection_option_array: [],
    inspection_selected_list: [],
    container_list:[],
    container_page:1,
    container_perpage:10,
    asset_owner:null,
    asset_type:null,
    status_type:null,
    triage_container_refetch:true,
    container_page_popup:false,
    dwnld_data:null
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case JOB_UPDATE_SUCCESS:
            return { ...state, ...action.payload }
        case CREATE_NEW_TRIAGE_PROD_JOB:
            return { ...state, ...action.payload }
        case GROUPED_CONTAINERS_FETCHING_COMPLETE:
            return { ...state, ...action.payload }
        case SET_STATE_VALUES:
            return { ...state, ...action.payload }
        case SET_PALLET_ARRIVAL_DATE:
            return { ...state, ...action.payload }
        case DELETE_SNAPSHOT_OBJ_SUCCESS:
            return { ...state, ...action.payload }
        case SET_PRINT_PAGE_DATA:
            return { ...state, ...action.payload }
        case FETCH_ASSET_FILE_DETAILS:
            return { ...state, ...action.payload };
        case PALLETS_OPERATORS_FETCHING_COMPLETE:
            return { ...state, ...action.payload };
        case CREATE_NEW_JOB:
            return { ...state, ...action.payload };
        case SET_JOBS_DATA:
            return { ...state, ...action.payload };
        case DETAILS_FETCHING_COMPLETE:
            return { ...state, ...action.payload };
        case JOBS_FETCHING_COMPLETE:
            return { ...state, ...action.payload };
        case FETCH_IMAGE:
            return {...state,...action.payload};
        case SET_IMAGE_DATA:
            return {...state, ...action.payload};
        case ADD_NEW_POPUP_PREVIEW: 
            return { ...state, image_popup: true}
        case SET_JOBS_PAGE:
            return { ...state, ...action.payload };
        case CONFIRM_CREATE_JOB:
            return { ...state, ...action.payload };
        case PALLET_MAIL_SENT_COMPLETE:
            return { ...state, ...action.payload };
        case DELETE_JOB_PREVIEW:
            return { ...state, delete_job: action.payload }
        case DELETE_JOB:
            return { ...state, joblist_refetch_force: true, search_job: "", del_btn_disable: false }
        case DELETE_JOB_DISMISS:
            return { ...state, delete_job: null }
        default:
            return state;
    }
};